import classNames from 'classnames';
import { navigate } from 'gatsby';
import * as React from 'react';
import { MouseEventHandler } from 'react';

type BodyPropsType = {
  onClick?: MouseEventHandler;
  text?: string;
  variant?: 'orange' | 'blue' | 'inverse' | 'white' | 'transparent';
  className?: string;
  to?: string;
  useDefaultSpacing?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button = ({
  onClick,
  text,
  variant = 'orange',
  className,
  to,
  useDefaultSpacing = true,
  ...rest
}: BodyPropsType) => {
  let style;
  let clickFn: MouseEventHandler<Element> | undefined;

  if (to) {
    clickFn = event => {
      event.preventDefault();
      navigate(to);
    };
  } else {
    clickFn = onClick;
  }

  switch (variant) {
    case 'blue':
      style = [
        'text-white',
        'bg-primary',
        'border-opacity-0',
        'pointer-fine:hover:bg-secondary',
      ];
      break;
    case 'white':
      style = [
        'bg-white',
        'border-opacity-0',
        'text-primary',
        'pointer-fine:hover:bg-secondary',
        'pointer-fine:hover:text-white',
      ];
      break;
    case 'inverse':
      style = [
        'bg-secondary',
        'border-opacity-0',
        'text-white',
        'pointer-fine:hover:border-opacity-100',
        'pointer-fine:hover:bg-white',
        'pointer-fine:hover:text-black',
      ];
      break;
    case 'transparent':
      style = [
        'text-black',
        'bg-transparent',
        'pointer-fine:hover:bg-secondary',
        'pointer-fine:hover:text-white',
        'pointer-fine:hover:border-opacity-0',
      ];
      break;
    default:
      style = [
        'text-black',
        'bg-white',
        'pointer-fine:hover:bg-secondary',
        'pointer-fine:hover:text-white',
        'pointer-fine:hover:border-opacity-0',
      ];
  }

  const buttonClass = classNames(
    {
      'min-w-11': useDefaultSpacing,
      'px-25px': useDefaultSpacing,
      'py-3': useDefaultSpacing,
    },
    'text-sm',
    'font-bold',
    'tracking-tight',
    'border',
    'border-black',
    'rounded',
    'transition-all',
    'duration-300',
    style,
    className,
  );

  return (
    <button className={buttonClass} onClick={clickFn} {...rest}>
      {text}
    </button>
  );
};

export default Button;

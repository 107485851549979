import React from 'react';
import Button from './Button';
import { termsContent } from '../content';

type LegalBodyPropsType = {
  htmlContent: any;
  buttonStyle: string;
};

function LegalBody({ htmlContent, buttonStyle }: LegalBodyPropsType) {
  const { home, goToTop } = termsContent.cta;

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <div className="flex justify-center">
      <div className="max-w-max w-full">
        <div
          className="space-y-4 md:space-y-5 lg:mb-10 mx-4 md:mx-24 max-1487px:mx-0 text-14px lg:text-16px text-left leading-6"
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        />
        <div className={buttonStyle}>
          <Button
            className="xl:w-48 w-7 xs:w-auto"
            text={home}
            to="/home"
            variant="inverse"
          />
          <Button
            className="xl:w-48 w-7 xs:w-auto"
            text={goToTop}
            onClick={() => scrollToTop()}
          />
        </div>
      </div>
    </div>
  );
}

export default LegalBody;

import * as React from 'react';
import LegalHeader from '../../components/LegalHeader';
import LegalBody from '../../components/LegalBody';
import DocumentHead from '../../components/DocumentHead';
import { termsContent } from '../../content';
// @ts-ignore
import htmlContent from '../../content/termsofuse.html';

const TermsOfUsePage = () => {
  const { header, metadata } = termsContent;
  const content = htmlContent.replace('{{year}}', new Date().getFullYear());
  return (
    <div data-testid="termsofuse-page-container" className="md-900px:pt-9 pt-7">
      <DocumentHead content={metadata} />
      <div data-testid="hero-container">
        <LegalHeader header={header.desktop} />
      </div>
      <div data-testid="terms-container">
        <LegalBody
          htmlContent={content}
          buttonStyle="flex justify-center space-x-3 xl:space-x-4 my-7 md:my-10"
        />
      </div>
    </div>
  );
};

export default TermsOfUsePage;
